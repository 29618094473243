import Head from 'next/head';

import { ThemeProvider } from 'next-themes';
import CookieConsent from './CookieConsent';
import Footer from './Footer';
import Nav from './Nav';

type Props = {
  children: any;
};

function Layout(props: Props) {
  return (
    <>
      <Head>
        <title>Bringmos • Südtiroler Bestellplattform</title>
        <link rel="icon" href="/icons/apple-touch-icon.png" />
      </Head>
      <ThemeProvider attribute="class" defaultTheme="system" enableSystem={true} storageKey="cp-theme">
        <div className="relative flex flex-col min-h-screen w-full bg-background text-on-background">
          <Nav></Nav>
          <main className="flex-grow w-full">{props.children}</main>
          <Footer></Footer>
          <CookieConsent></CookieConsent>
        </div>
      </ThemeProvider>
    </>
  );
}

export default Layout;
