export enum Palette {
  Purple = '#604b87',
  Blue = '#4480C0',
  Pink = '#B9607F',
  Gold = '#A17504',
  Green = '#3F8C54',
  Red = '#C06159',
}

export function paletteToClass(palette: Palette) {
  switch (palette) {
    case Palette.Purple:
      return 'purple';
    case Palette.Blue:
      return 'blue';
    case Palette.Pink:
      return 'pink';
    case Palette.Gold:
      return 'gold';
    case Palette.Green:
      return 'green';
    case Palette.Red:
      return 'red';
    default:
      return 'purple';
  }
}

export function themeToPalette(themeClass?: string) {
  switch (themeClass) {
    case 'purple':
      return Palette.Purple;
    case 'blue':
      return Palette.Blue;
    case 'pink':
      return Palette.Pink;
    case 'gold':
      return Palette.Gold;
    case 'green':
      return Palette.Green;
    case 'red':
      return Palette.Red;
    default:
      return Palette.Purple;
  }
}

export function computeColors(palette: Palette, isDark: boolean, documentId?: string) {
  let element: HTMLElement;
  if (documentId) {
    element = document.getElementById(documentId);
  } else {
    element = document.documentElement;
  }
  let classToSet = paletteToClass(palette);

  //   if (!element.classList.contains(classToSet)) {
  element.classList.remove('purple', 'blue', 'pink', 'gold', 'green', 'red');
  element.classList.remove('dark', 'light');
  element.classList.add(classToSet);
  element.classList.add(isDark ? 'dark' : 'light');
  //   }
}
