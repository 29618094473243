import classNames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';

export enum ButtonSizes {
  Small = 'Small',
  Large = 'Large',
}

export enum ButtonVariants {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Destructive = 'Destructive',
}

export enum ButtonColors {
  Neutral = 'Neutral',
  Primary = 'Primary',
  Warn = 'Warn',
}

export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  size?: ButtonSizes;
  variant?: ButtonVariants;
  color?: ButtonColors;
};

export const getButtonClasses = (size: ButtonSizes, variant: ButtonVariants, color: ButtonColors) =>
  classNames({
    'button-base': true,
    'shadow disabled:shadow-none hover:shadow-xl active:shadow-xl disabled:border-none disabled:bg-gray-300 disabled:text-gray-600 disabled:cursor-not-allowed disabled:dark:bg-gray-700 disabled:dark:text-gray-900':
      variant === ButtonVariants.Primary,
    'bg-primary text-on-primary': variant === ButtonVariants.Primary && color !== ButtonColors.Warn,
    'bg-error text-on-error': variant === ButtonVariants.Primary && color === ButtonColors.Warn,
    'border border-outline text-gray-950 hover:bg-gray-500 hover:bg-opacity-20 hover:dark:bg-white hover:dark:bg-opacity-10 focus:bg-gray-500 focus:bg-opacity-20 focus:dark:bg-white focus:dark:bg-opacity-10 dark:text-white disabled:text-gray-600 disabled:hover:bg-transparent disabled:dark:hover:bg-transparent disabled:cursor-not-allowed disabled:dark:text-gray-900':
      variant === ButtonVariants.Secondary,
    '!bg-error !text-on-error': variant === ButtonVariants.Destructive,
    'border border-outline-variant text-warn-light-500 dark:text-warn-dark-500 hover:bg-warn-light-500 hover:bg-opacity-10 dark:hover:bg-warn-light-500 dark:hover:bg-opacity-10 focus:bg-warn-light-500 focus:bg-opacity-20 dark:focus:bg-warn-light-500 dark:focus:bg-opacity-20':
      color === ButtonColors.Warn && variant !== ButtonVariants.Primary,
    'px-16 py-2': size === ButtonSizes.Large,
    'px-4 h-36px': size === ButtonSizes.Small,
  });

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className = '',
      variant = ButtonVariants.Primary,
      size = ButtonSizes.Small,
      color = ButtonColors.Primary,
      ...props
    },
    ref,
  ) => {
    return (
      <button type="button" ref={ref} className={`${getButtonClasses(size, variant, color)} ${className}`} {...props}>
        {children}
      </button>
    );
  },
);
