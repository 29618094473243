import { ComputerDesktopIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import { useTheme } from 'next-themes';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { computeColors, Palette } from '../utils/colors';

function Theme() {
  const { resolvedTheme, setTheme, systemTheme } = useTheme();
  const router = useRouter();

  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  function setAndGenerateColors(theme: string) {
    setTheme(theme);
    const isDark = theme === 'system' ? systemTheme === 'dark' : theme === 'dark';
    computeColors(Palette.Purple, isDark);
  }

  useEffect(() => {
    // if (router.pathname !== '/[...slug]') {
    computeColors(Palette.Purple, resolvedTheme === 'dark');
    // }
  }, [resolvedTheme]);

  return !isSSR ? (
    <div
      className={`mt-4 w-fit relative grid grid-cols-3 rounded-full border border-outline-variant bg-secondary-container text-on-secondary-container p-1`}
    >
      <button
        className={`h-8 w-8 rounded-full flex flex-row items-center justify-center hover:opacity-100 transition-all ${
          resolvedTheme === 'dark' ? 'bg-black/10 dark:bg-white/10' : 'opacity-60'
        }`}
        onClick={() => setAndGenerateColors('dark')}
      >
        <MoonIcon className="flex-shrink-0 h-4 w-4 mt-[2px]" />
      </button>
      <button
        className={`h-8 w-8 rounded-full flex flex-row items-center justify-center hover:opacity-100 transition-all ${
          resolvedTheme === 'light' ? 'bg-black/10 dark:bg-white/10' : 'opacity-60'
        }`}
        onClick={() => setAndGenerateColors('light')}
      >
        <SunIcon className="flex-shrink-0 h-6 w-6" />
      </button>
      <button
        className={`h-8 w-8 rounded-full flex flex-row items-center justify-center hover:opacity-100 transition-all ${
          false ? 'bg-black/10 dark:bg-white/10' : 'opacity-60'
        }`}
        onClick={() => setAndGenerateColors('system')}
      >
        <ComputerDesktopIcon className="flex-shrink-0 h-5 w-5" />
      </button>
    </div>
  ) : null;
}

export default Theme;
